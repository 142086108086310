<template>
  <div>
    <InfoPedido
      :pedido="currentPedido"
      :close="closeInfo"      
    />    
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Timeline">
        <div class="xrow" style="margin-bottom: 10px">
          <div class="col-1-4">
            <label>&nbsp;Pedido</label><br>
            <input class="form-control" v-model="searchPedido">              
          </div>          
          <div class="col-1-4">
            <label>&nbsp;</label><br>
            <button class="button button-primary" v-on:click="loadPedidos">
              Buscar
            </button>
          </div>
        </div>
        <ListaPedidos
          :pedidos="pedidos"
          :openInfo="openInfo"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import TemplateModulo from '../../../components/TemplateModulo';
import ListaPedidos from './List';
import { get, post, del } from '../../../helpers/apiRequest';
import InfoPedido from './InfoPedido';

export default {
  name: 'pedido-pendente-integracao',
  components: {
    InfoPedido,
    TemplateModulo,
    ListaPedidos,
  },
  data() {
    return {
      currentPedido: { motivo: '' },
      pedidos: [],
      loading: true,
      searchPedido: null,
    };
  },

  computed: {
    
  },

  methods: {
    loadPedidos() {
      if (this.searchPedido) {
        this.loading = true;
        get(
          `/admin/pedidos/timeline/${this.searchPedido}`
        )
          .then((pedidos) => {
            this.loading = false;
            this.pedidos = pedidos;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },    
    openInfo(pedido) {
      this.loading = true;
      this.currentPedido = pedido;
      this.$modal.show('info-pedido');        
    },
    closeInfo() {
      this.loading = false;
      this.error = false;
      this.$modal.hide('info-pedido');
    },
    showSuccess(message, callback) {
      this.$swal({
        title: 'Sucesso!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Fechar',
      }).then(() => {
        callback ? callback() : null;
      });
    },
    showError(message, callback) {
      this.$swal({
        title: 'Ops, algo deu errado!',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Fechar',
      }).then(() => {
        callback ? callback() : null;
      });
    },
  },

  beforeMount() {
    
  },
};
</script>
