<style scoped>
.modificado-icon {
  margin-right: 10px;
  color: #636f83;
}
</style>
<template>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Evento</th>
                <th>Origem</th>
                <th>Colaborador</th>
                <th>Cliente</th>
                <th>Data Evento</th>
                <th>Itens</th>
                <th>Total</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="pedido in pedidos" :key="pedido.id">
                    <td class="highlight">{{ pedido.id }}</td>
                    <td>
                      <span class="label"
                        :class="[
                          pedido.modificado_auto
                          ? 'warning'
                          : pedido.header_historico.evento_cor
                      ]">{{ pedido.header_historico.evento }}</span>
                    </td>
                    <td>
                        <span class="label secondary">{{ pedido.header_historico.origem }}</span>
                    </td>
                    <td>{{ pedido.header_historico.colaborador_id }}</td>
                    <td>{{ pedido.cliente.razaosocial }}</td>
                    <td>{{ convertDataHora(pedido.header_historico.datahora_criacao) }}</td>
                    <td>{{ pedido.quantidadeitens }}</td>
                    <td>{{ pedido.valortotal | monetary }}</td>
                    <td class="actions">
                        <fa-icon v-if="pedido.modificado_auto" icon="pencil-alt" class="modificado-icon"/>
                        <button class="button button-info" type="button" v-on:click="openInfo(pedido)">
                            <fa-icon icon="info-circle"/>
                        </button>
                    </td>
                </tr>
                <tr v-if="pedidos.length === 0">
                    <td colspan="7" class="center">
                        Nenhum pedido encontrado
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

  export default {
    name: "lista-pedidos",
    props: [
      'pedidos',
      'openInfo'
    ],
    methods: {
        convertDataHora(dataHora) {
            return moment(String(dataHora)).format('DD/MM/YYYY HH:mm:ss')
        },
    }
  }
</script>