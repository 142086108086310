<style scoped>
table tr td {
  padding: 4px;
  font-size: 12px;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

.error-integradora {
  border-top: 2px solid transparent;
}

.error-integradora .error {
  font-size: 8px;
  padding: 0px 10px 10px 10px;
}

.removido {
  color:#e55353;
}

.modificado {
  color:#df970e;
}
</style>

<template>
  <modal name="info-pedido" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <div class="modalbox" v-if="!!pedido.id">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Detalhes do Orçamento">
            <h2>Cabeçalho</h2>
            <div class="table-responsive">
              <table class="table table-hover">
                <tr>
                  <td>
                    <small>Orçamento</small>
                    <p>{{ pedido.id }}</p>
                  </td>
                  <td>
                    <small>Origem</small>
                    <p>{{ pedido.origem }}</p>
                  </td>
                  <td>
                    <small>Grupo</small>
                    <p>{{ pedido.grupo }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>Cliente</small>
                    <p>{{ pedido.cliente.id }} - {{ pedido.cliente.razaosocial }}</p>
                  </td>
                  <td>
                    <small>Colaborador</small>
                    <p>{{ pedido.colaborador.id }} - {{ pedido.colaborador.nome }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>Dt. Atualização</small>
                    <p>{{ pedido.dataatualizacao | datetime }}</p>
                  </td>
                  <td>
                    <small>Cobrança</small>
                    <p>{{ pedido.cobranca.id }} - {{ pedido.cobranca.descricao }}</p>
                  </td>
                  <td>
                    <small>Plano Pagamento</small>
                    <p>{{ pedido.planopagamento.id }} - {{ pedido.planopagamento.descricao }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>Itens</small>
                    <p>{{ pedido.quantidadeitens }}</p>
                  </td>
                  <td>
                    <small>Total</small>
                    <p>{{ pedido.valortotal | monetary }}</p>
                  </td>
                  <td>
                    <small>Filial</small>
                    <p>{{ pedido.filial.id }} - {{ pedido.filial.fantasia }}</p>
                  </td>
                </tr>
              </table>
            </div>

            <h2>Itens</h2>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Cód.</th>
                  <th>Seq.</th>
                  <th>Produto</th>
                  <th>Qt</th>
                  <th>Vl.Unit</th>
                  <th>Vl.Tot</th>
                  <th>Motivo</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in pedido.itens" >
                  <tr :key="item.produto_id" :class="{'modificado': item.motivo}">
                    <td>{{ item.produto_id }}</td>
                    <td>{{ item.sequenciainsercao }}</td>
                    <td>{{ item.descricaocompleta }}</td>
                    <td>{{ item.quantidade }}</td>
                    <td>R$ {{ item.valorunitario }}</td>
                    <td>R$ {{ item.valortotal }}</td>
                    <td>{{ item.motivo }}</td>
                  </tr>                  
                </template>
                </tbody>
              </table>
            </div>
            
            <template v-if="pedido.itens_removidos != 0">
            <h2>Itens Removidos automaticamente</h2>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Cód.</th>
                  <th>Seq.</th>
                  <th>Produto</th>
                  <th>Qt</th>
                  <th>Vl.Unit</th>
                  <th>Vl.Tot</th>
                  <th>Motivo</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in pedido.itens_removidos" >
                  <tr :key="item.produto_id" class="removido">
                    <td>{{ item.produto_id }}</td>
                    <td>{{ item.sequenciainsercao }}</td>
                    <td>{{ item.descricaocompleta }}</td>
                    <td>{{ item.quantidade }}</td>
                    <td>R$ {{ item.valorunitario }}</td>
                    <td>R$ {{ item.valortotal }}</td>
                    <td>{{ item.motivo }}</td>
                  </tr>                  
                </template>
                </tbody>
              </table>
            </div>
            </template>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">      
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";

export default {
  name: "info-pedido",
  props: ["close", "pedido"],
  components: { CloseModal },
  methods: {
    
  },
};
</script>
